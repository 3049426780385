import moment from 'moment';
import enums from 'enums/index';

export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
export const GOOGLE_MAP_SEARCH_BY_ADDRESS_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&address=`;
const currentDate = moment();
const march2022 = moment('2022-03-01'); // the date where juhi software was released
const numberOfMonths = currentDate.diff(march2022, 'months') + 1;

export const MAP_SETTINGS = {
  DEFAULT_MAP_OPTIONS: {
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
  },
  DEFAULT_CENTER: { lat: 52.52, lng: 13.405 },
  DEFAULT_ZOOM: 12,
  MARKER_HEIGHT: 45,
  PIXEL_OFFSET: {
    MARKER: {
      X: 0,
      Y: -42,
    },
  },
};

export const SYSTEM_START_DATE = '2018-11-01';

export const GERMANY_BOUNDING_BOX = '5.98865807458,47.3024876979,15.0169958839,54.983104153';

export const BOUNDING_BOXES = {
  Berlin: '12.6978,52.1653,14.0553,52.8739',
  Leipzig: '11.9126,51.1082,12.83,51.5959',
  Dresden: '13.2214,50.7865,14.2349,51.3528',
  Chemnitz: '12.4249,50.5533,13.4384,51.1224',
  Köln: '6.3847,50.7578,7.7649,51.1537',
  Düsseldorf: '6.6888,51.3525,6.9399,51.1244',
  Duisburg: '6.6256,51.5601,6.8303,51.3334',
  Bochum: '7.1021,51.5314,7.3493,51.4105',
  Essen: '6.8943,51.5342,7.1377,51.3476',
  Dortmund: '7.3024,51.6000,7.6382,51.4155',
  Frankfurt: '8.4727605,50.2271424,8.8004049,50.0153529',
  Wiesbaden: '8.1106718,50.1518098,8.3861621,49.9932752',
  Kiel: '9.9482753666,54.2265700189,10.3128747337,54.4299243037',
  Neumünster: '9.6406695284,53.9901687666,10.2333162145,54.2619984667',
  Freiburg: '7.5669644955,47.7154429123,8.1247566822,48.2733466783',
};
// 12.941511,52.314001,13.732527,52.721802
export const CLIENT_STATUS_COLOR = {
  [enums.ClientStatus.NOT_INTERESTED]: '#bd5734',
  [enums.ClientStatus.DRAFT]: '#f3b836',
  [enums.ClientStatus.ACTIVE]: '#89DA59',
  [enums.ClientStatus.INACTIVE]: '#9c27b0',
  [enums.ClientStatus.TERMINATED]: '#FF5349',
  [enums.ClientStatus.FREEZED]: '#034f84',
};

export const StatisticsRangeOptions = {
  [enums.StatisticsType.DAILY]: [
    { label: '7 days', value: 7 },
    { label: '14 days', value: 14 },
    { label: '30 days', value: 30 },
  ],
  [enums.StatisticsType.WEEKLY]: [
    { label: '4 weeks', value: 4 },
    { label: '8 weeks', value: 8 },
    { label: '12 weeks', value: 12 },
  ],
  [enums.StatisticsType.MONTHLY]: [
    { label: '3 months', value: 3 },
    { label: '6 months', value: 6 },
    { label: '12 months', value: 12 },
    { label: 'All months', value: numberOfMonths },
  ],
  [enums.StatisticsType.ANNUALLY]: [
    { label: '3 years', value: 3 },
    { label: '5 years', value: 5 },
    { label: '10 years', value: 10 },
  ],
};
export const monthNamesShort = [
  'Jan.',
  'Feb.',
  'März',
  'Apr',
  'Mai',
  'Juni',
  'Juli.',
  'Aug',
  'Sept.',
  'Okt.',
  'Nov.',
  'Dez.',
];

export const dayNames = {
  de: [
    { name: 'Montag', value: enums.Days.MONDAY },
    { name: 'Dienstag', value: enums.Days.TUESDAY },
    { name: 'Mittwoch', value: enums.Days.WEDNESDAY },
    { name: 'Donnerstag', value: enums.Days.THURSDAY },
    { name: 'Freitag', value: enums.Days.FRIDAY },
    { name: 'Samstag', value: enums.Days.SATURDAY },
    { name: 'Sonntag', value: enums.Days.SUNDAY },
  ],
  en: [
    { name: 'Monday', value: enums.Days.MONDAY },
    { name: 'Tuesday', value: enums.Days.TUESDAY },
    { name: 'Wednesday', value: enums.Days.WEDNESDAY },
    { name: 'Thursday', value: enums.Days.THURSDAY },
    { name: 'Friday', value: enums.Days.FRIDAY },
    { name: 'Saturday', value: enums.Days.SATURDAY },
    { name: 'Sunday', value: enums.Days.SUNDAY },
  ],
};

export const preferredTimesOptions = {
  de: [
    { name: 'Vormittags', value: enums.PreferredTimes.MORNING },
    { name: 'Nachmittage', value: enums.PreferredTimes.AFTERNOON },
    { name: 'Ganztägig', value: enums.PreferredTimes.ALLDAY },
  ],
  en: [
    { name: 'Morning', value: enums.PreferredTimes.MORNING },
    { name: 'Afternoon', value: enums.PreferredTimes.AFTERNOON },
    { name: 'All Day', value: enums.PreferredTimes.ALLDAY },
  ],
};

export const SERVICE_PLAN_HOURS = {
  [enums.ServicePlan.BASIC]: 4,
  [enums.ServicePlan.BEST_CHOICE]: 8,
  [enums.ServicePlan.PREMIUM]: 10,
};

export const CLIENT_EUROS_PER_HOUR = 31.25;
export const CLIENT_EUROS_PER_HOUR_FRANKFURT_WIESBADEN = 25;
export const SERVICE_PLAN_BACKGROUND_COLOR = {
  [enums.ServicePlan.BASIC]: '#ecedf7',
  [enums.ServicePlan.BEST_CHOICE]: '#edf8ec',
  [enums.ServicePlan.PREMIUM]: '#fff9e5',
};
export const SERVICE_PLAN_COLOR = {
  [enums.ServicePlan.BASIC]: '#414bb2',
  [enums.ServicePlan.BEST_CHOICE]: '#6eca66',
  [enums.ServicePlan.PREMIUM]: '#e3a402',
};
export const INVITATION_SORTING_PRIORITIES = {
  [enums.InvitationStatus.PENDING]: 0,
  [enums.InvitationStatus.SCHEDULED]: 0,
  [enums.InvitationStatus.HOLD]: 0,
  [enums.InvitationStatus.ACCEPTED]: 1,
  [enums.InvitationStatus.MATCHED]: 2,
  [enums.InvitationStatus.COMPLETED]: 3,
  [enums.InvitationStatus.CANCELED]: 4,
  [enums.InvitationStatus.FAILED]: 5,
  [enums.InvitationStatus.REJECTED]: 6,
  [enums.InvitationStatus.EXPIRED]: 7,
};

export const COMMON_HELPER_DOCUMENT_CONTRACT_TYPES = [
  { name: 'PDF', value: 'pdf' },
  { name: 'ODT', value: 'odt' },
];

export const DEFAULT_HELPER_DOCUMENT_CONTRACT_TYPES = [
  ...COMMON_HELPER_DOCUMENT_CONTRACT_TYPES,
  { name: 'Word', value: 'docx' },
];

export const MINIMUM_DAYS_TO_TERMINATE_HELPER = 28;
export const MINIMUM_DAYS_TO_TERMINATE_CLIENT = 14;
export const GERMAN_DATE_FORMAT = 'DD.MM.YYYY';
